import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { postStyle } from '../templates/postStyle';
import { SEO } from '../components/seo/seo';
import { DefaultHeader } from '../components/Header/Header';
import { DefaultFooter } from '../components/Footer/Footer';
import { Parallax } from '../components/Parallax/Parallax';

const useStyles = makeStyles(postStyle);

const cachedScripts: string[] = [];
/**
 * see for inspiration https://usehooks.com/useScript/
 * TODO: even with useEffect, there was not able to load it dynamically when the GatsbyLink was used...maybe one day it can be done better, for now /cookies page will be always hardly refreshed
 */
function useScript(src: string, id: string, insideElement: string) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        });
      } else {
        cachedScripts.push(src);

        // Create script
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.id = id;

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
        };

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src);
          if (index >= 0) cachedScripts.splice(index, 1);
          script.remove();

          setState({
            loaded: true,
            error: true,
          });
        };

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);

        // Add script to document body
        const insideEl = document.getElementById(insideElement);
        if (insideEl) {
          insideEl.appendChild(script);
        }

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener('load', onScriptLoad);
          script.removeEventListener('error', onScriptError);
        };
      }
    },
    [src, id, insideElement], // Only re-run effect if the src, id, or insideElement changes
  );

  return [state.loaded, state.error];
}

// Github Actions sucks, kick off the build by changing something...part II
const CookiesPage: FC = () => {
  const classes = useStyles();
  useScript(
    `https://consent.cookiebot.com/${process.env.GATSBY_COOKIEBOT_CBIT}/cd.js`,
    'CookieDeclaration',
    'CookieDeclarationUserStatusPanelArea',
  );

  return (
    <div>
      <SEO featuredImage="/img/bloggingStation.jpg" />
      <DefaultHeader />

      <Parallax image="/img/bloggingStation.jpg" className={classes.parallax} filter="dark">
        <div className={classes.container}>
          <Grid container justify="center">
            <Grid item md={9} className={classes.textCenter}>
              <Typography component="h1" variant="h3" className={classes.title}>
                Cookie Statement
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Parallax>

      <div className={classes.main} style={{ marginBottom: '60px', minHeight: '200px' }}>
        <div className={classes.container}>
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={10}>
              <div id="CookieDeclarationUserStatusPanelArea" style={{ padding: '10px 0' }} />
            </Grid>
          </Grid>
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
};
export default CookiesPage;
